import Vue from 'vue';
import App from './components/App';

require('../css/app.scss');

const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

// Icons for Layout
//require("./kit_awesome");

// require jQuery normally
const $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;
// this "modifies" the jquery module: adding behavior to it
require('popper.js');
// the bootstrap module doesn't export/return anything
require('bootstrap');
require('bootstrap-select');

/**
 * lazy loading pictures
 * https://github.com/verlok/lazyload
 *
 * @type {LazyLoad}
 */
import lazyLoadInit from "./lazyload-init";
lazyLoadInit();


// Searchin Field
require('jquery-typeahead');

// auskommentieren, wenn Vue verwendet werden soll
/*
new Vue({
    el: '#app',
    render: h => h(App)
});
*/
