var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "center" }, [_vm._v("My Application")]),
    _vm._v(" "),
    _c("div", { domProps: { textContent: _vm._s(_vm.message) } }),
    _vm._v("\n  " + _vm._s(_vm.message) + "\n  "),
    _c(
      "ul",
      _vm._l(_vm.words, function(word) {
        return _c("li", { key: word.id }, [_vm._v(_vm._s(word))])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }